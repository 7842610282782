<template lang="pug">
div
</template>
<script setup lang="ts">
import PhotoSwipe from 'photoswipe'
import 'photoswipe/style.css'
const emit = defineEmits(['closed'])
const { items: dataSource, index } = defineProps<{ items: PhotoswipeItem[]; index: number }>()

onMounted(() => {
  const pswipe = new PhotoSwipe({
    bgOpacity: 1,
    index,
    dataSource,
  })
  pswipe.on('close', () => {
    emit('closed')
  })

  pswipe.on('uiRegister', () => {
    pswipe.ui?.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      onInit(el, pswp) {
        pswp.on('change', () => {
          const currentEl = pswipe.currSlide
          el.innerHTML = currentEl?.data.caption || ''
          el.style =
            'background: black; color: #fff; padding: 10px 2px; position: fixed; width: 100%; bottom: 0px; text-align: center;'
        })
      },
    })
  })

  pswipe.init()

  onUnmounted(() => {
    pswipe.destroy()
  })
})
</script>
